<template>
  <div>
    <!-- Modal -->
    <div id="modal_aside_mapa" class="modal fixed-left fade" role="dialog">
      <div class="modal-dialog modal-dialog-aside" role="document">
        <div class="modal-content">
          <div class="modal-header bg-frontera pt-1 pb-1">
            <h3 style="font-size: 16px" class="modal-title text-white">
              <b>Opciones Mapa</b>
            </h3>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              ref="closeModal"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row"></div>
            <div class="row" v-if="$parent.form.geocercas == true">
              <div
                class="col-md-11"
                :class="
                  $parent.markersTraza.length > 0 ? 'col-md-11' : 'col-md-12'
                "
              >
                <label for="geocerca">Geocerca</label>
                <v-select
                  :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                  v-model="filter.geocerca"
                  placeholder="Geocerca"
                  label="desc"
                  :options="$parent.locations.geocerca_poli"
                  style="font-size: 10px; background-color: #fff"
                ></v-select>
              </div>
              <div class="col-md-1 pt-4" v-if="$parent.markersTraza.length > 0">
                <button
                  type="button"
                  class="btn btn-dark btn-sm"
                  @click="$parent.cleanMarkers()"
                >
                  <i class="fas fa-brush"></i>
                </button>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label for="empresa">Empresa</label>
                  <v-select
                    :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                    placeholder="Razon Social"
                    v-model="empresa"
                    label="razon_social"
                    :options="listas.empresas"
                    @search="buscarEmpresas"
                    @input="selectEmpresa()"
                    style="font-size: 10px; background-color: #fff"
                    multiple
                  ></v-select>
                </div>
              </div>
              <div
                class="form-group col-md-12"
                v-if="
                  $parent.form.vehiculosAct == true ||
                    $parent.form.vehiculosInact == true
                "
              >
                <label for="turno"> # Turno</label>
                <v-select
                  :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                  v-model="filter.turno"
                  placeholder="# Turno"
                  style="font-size: 10px"
                  taggable
                  multiple
                ></v-select>
              </div>
              <div class="form-group col-md-12">
                <label for="estado">Bloque</label>
                <v-select
                  :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                  v-model="bloque"
                  label="nombre"
                  :options="listas.bloques"
                  style="font-size: 10px; background-color: #fff"
                  placeholder="Bloque"
                  @search="buscarBloques"
                  @input="selectBloque()"
                  multiple
                ></v-select>
              </div>
              <div class="form-group col-md-12">
                <label for="operacion">Operación</label>
                <v-select
                  :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                  v-model="operacion"
                  label="Operacion"
                  :options="listas.operacion"
                  style="font-size: 10px; background-color: #fff"
                  placeholder="Operación"
                  @search="buscarOperacion"
                  @input="selectOperacion()"
                  :disabled="
                    filter.bloque_id == null || !filter.bloque_id.length > 0
                  "
                  multiple
                ></v-select>
              </div>
              <div class="form-group col-md-12">
                <label for="estado">Ruta</label>
                <v-select
                  :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                  v-model="ruta"
                  label="nombre"
                  :options="listas.ruta"
                  style="font-size: 10px; background-color: #fff"
                  placeholder="Ruta"
                  @search="buscarRuta"
                  @input="selectRuta()"
                  multiple
                ></v-select>
              </div>
              <div class="col-md-12">
                <div class="form-group">
                  <label for="vehículo">Vehículo</label>
                  <v-select
                    :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                    v-model="vehiculo"
                    label="placa"
                    :options="listas.vehiculos"
                    style="font-size: 10px; background-color: #fff"
                    @search="buscarVehiculo"
                    @input="selectVehiculo()"
                    placeholder="Placa"
                    multiple
                  ></v-select>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group">
                  <label for="vehículo">Conductor</label>
                  <v-select
                    :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                    v-model="conductor"
                    placeholder="Conductor"
                    label="nConductor"
                    style="font-size: 10px"
                    :options="listas.conductores"
                    @search="buscarConductor"
                    @input="selectConductor()"
                    multiple
                  ></v-select>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Cerrar
              <i class="fas fa-times"></i>
            </button>
            <button
              type="button"
              class="btn btn-dark"
              @click="limpiarFiltros()"
            >
              Limpiar
              <i class="fas fa-trash"></i>
            </button>
            <button
              type="button"
              class="btn btn-primary"
              @click="$parent.showCarros(1)"
            >
              Buscar
              <i class="fas fa-search"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import vSelect from "vue-select";

export default {
  name: "TifMapasOptions",
  components: {
    vSelect,
  },
  data() {
    return {
      empresa: null,
      estado: null,
      conductor: null,
      operacion: null,
      ruta: null,
      vehiculo: null,
      bloque: null,
      filter: {
        turno: [],
        estado: [],
        empresa: [],
        conductor: [],
        operacion: [],
        ruta: [],
        vehiculo: [],
        bloque: [],
        geocerca: null,
        ruta_id: null,
        empresa_id: null,
        operacion_id: null,
        bloque_id: null,
        conductor_id: null,
        vehiculo_id: null,
        producto_id: null,
      },
      listas: {
        empresas: [],
        ruta: [],
        conductores: [],
        vehiculos: [],
        operacion: [],
        bloques: [],
      },
    };
  },

  methods: {
    buscarEmpresas(search, loading) {
      if (search != "") {
        let me = this;
        loading(true);
        var url = "api/admin/empresas/lista?razon=" + search;
        axios
          .get(url, {
            params: {
              linea_negocio_id: 3,
            },
          })
          .then(function(response) {
            let respuesta = response.data;
            me.listas.empresas = respuesta;
            loading(false);
          })
          .catch(function(error) {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error" + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    buscarRuta(search, loading) {
      if (search != "") {
        let me = this;
        loading(true);
        var url = "api/tif/mapas/rutas/lista?nombre=" + search;
        axios
          .get(url)
          .then(function(response) {
            let respuesta = response.data;
            me.listas.ruta = respuesta;
            loading(false);
          })
          .catch(function(error) {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error" + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    buscarOperacion(search, loading) {
      if (search != "") {
        let me = this;
        loading(true);
        var url = "api/tif/mapas/operacion/lista?descripcion=" + search;
        axios
          .get(url, {
            params: {
              bloque_id: this.filter.bloque_id,
            },
          })
          .then(function(response) {
            let respuesta = response.data;
            me.listas.operacion = respuesta;
            loading(false);
          })
          .catch(function(error) {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error" + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    selectRuta() {
      this.filter.ruta_id = this.ruta.map((e) => e.id);
    },

    selectEmpresa() {
      this.filter.empresa_id = this.empresa.map((e) => e.id);
    },

    selectOperacion() {
      this.filter.operacion_id = this.operacion.map((e) => e.id);
    },

    selectBloque() {
      this.filter.bloque_id = null;
      if (this.bloque.length > 0) {
        this.filter.bloque_id = this.bloque.map((e) => e.id);
      } else {
        this.filter.operacion_id = null;
        this.operacion = null;
      }
    },

    selectConductor() {
      this.filter.conductor_id = this.conductor.map((e) => e.id);
    },

    selectVehiculo() {
      this.filter.vehiculo_id = this.vehiculo.map((e) => e.id);
    },

    buscarBloques(search, loading) {
      if (search != "") {
        let me = this;
        loading(true);
        var url = "api/admin/bloques/lista?nombre=" + search;
        axios
          .get(url, {
            params: {
              linea_negocio_id: [3],
            },
          })
          .then(function(response) {
            let respuesta = response.data;
            me.listas.bloques = respuesta;
            loading(false);
          })
          .catch(function(error) {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error" + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    buscarConductor(search, loading) {
      if (search != "") {
        let me = this;
        loading(true);
        var url = "api/admin/conductores/lista?numero_documento=" + search;
        axios
          .get(url, {
            params: {
              linea_negocio_id: [3],
            },
          })
          .then(function(response) {
            let respuesta = response.data;
            me.listas.conductores = respuesta;
            loading(false);
          })
          .catch(function(error) {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error" + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    buscarVehiculo(search, loading) {
      if (search != "") {
        let me = this;
        loading(true);
        var url = "api/admin/vehiculos/lista?placa=" + search;
        axios
          .get(url, {
            params: {
              linea_negocio_id: [3],
            },
          })
          .then(function(response) {
            let respuesta = response.data;
            me.listas.vehiculos = respuesta;
            loading(false);
          })
          .catch(function(error) {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error" + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    limpiarFiltros() {
      this.filter = {};
      this.filter.estado = [];
      this.filter.vehiculo = [];
      this.filter.empresa = [];
      this.filter.operacion = [];
      this.filter.bloque = [];
      this.filter.conductor = [];
      this.filter.ruta = [];
      this.filter.geocerca = [];
      this.operacion = null;
      this.empresa = null;
      this.estado = null;
      this.vehiculo = null;
      this.conductor = null;
      this.bloque = null;
      this.ruta = null;
    },
  },
  mounted() {},
};
</script>
<style>
.modal .modal-dialog-aside {
  width: 440px;
  max-width: 50%;
  height: 100%;
  margin: 0;
  transform: translate(2);
  transition: transform 0.2s;
}
.modal .modal-dialog-aside .modal-content {
  height: inherit;
  border: 1;
  border-radius: 1;
}
.modal .modal-dialog-aside .modal-content .modal-body {
  overflow-y: auto;
}
.modal.fixed-left .modal-dialog-aside {
  margin-left: auto;
  transform: translateX(100%);
}
.modal.fixed-right .modal-dialog-aside {
  margin-right: auto;
  transform: translateX(-100%);
}
.modal.show .modal-dialog-aside {
  transform: translateX(0);
}
</style>
