<template>
  <div class="card">
    <div class="card-header">
      <ul class="nav nav-tabs" id="myTab" role="tablist">
        <li class="nav-item">
          <a
            class="nav-link"
            id="tab-Ve"
            data-toggle="tab"
            href="#Ve_inAc"
            v-show="Object.keys($parent.vehiculosInAc).length > 0"
            ><b>Inactivos</b></a
          >
        </li>
        <li class="nav-item">
          <a
            class="nav-link"
            id="tab-Activos"
            data-toggle="tab"
            href="#Activos"
            v-show="Object.keys($parent.vehiculosAct).length > 0"
            ><b>Activos</b></a
          >
        </li>
        <li class="nav-item">
          <a
            class="nav-link"
            id="tab-Postulados"
            data-toggle="tab"
            href="#Postulados"
            v-show="Object.keys($parent.vehiculosPostulados).length > 0"
            ><b>Postulados</b></a
          >
        </li>
      </ul>
      <div class="tab-content" id="myTabContent">
        <div
          class="tab-pane fade"
          id="Ve_inAc"
          v-show="Object.keys($parent.vehiculosInAc).length > 0"
        >
          <div class="card-body table-responsive">
            <div class="row">
              <div class="col-md-12">
                <table
                  id="cars"
                  class="
                    table table-bordered table-striped table-hover table-sm
                  "
                >
                  <thead>
                    <tr>
                      <th colspan="7"></th>
                      <th colspan="2" style="text-align: center">
                        Ultimo Registro
                      </th>
                    </tr>
                    <tr>
                      <th>
                        Vehiculo
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.vehiculo_fil_in"
                          @keyup.enter="$parent.getIndex()"
                        />
                      </th>
                      <th>
                        Empresa
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.empresa_nombre_in"
                          @keyup.enter="$parent.getIndex()"
                        />
                      </th>
                      <th>
                        Conductor
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.conductor_in"
                          @keyup.enter="$parent.getIndex()"
                        />
                      </th>
                      <th>
                        Bloque
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.bloque_in"
                          @keyup.enter="$parent.getIndex()"
                        />
                      </th>
                      <th>
                        Operación
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.operacion_in"
                          @keyup.enter="$parent.getIndex()"
                        />
                      </th>
                      <th>
                        Ruta
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.ruta_in"
                          @keyup.enter="$parent.getIndex()"
                        />
                      </th>
                      <th>Fecha de su ultima posicion</th>
                      <th>Latitud</th>
                      <th>Longitud</th>
                      <th>Dirección</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="item in $parent.vehiculosInAc.data"
                      :key="item.id"
                    >
                      <td>
                        {{ item.placa }}
                      </td>
                      <td>
                        {{ item.empresa.razon_social }}
                      </td>
                      <td v-if="item.tif_turno">
                        {{ item.tif_turno.conductor.nombres }}
                        {{ item.tif_turno.conductor.apellidos }}
                      </td>
                      <td v-else></td>
                      <td v-if="item.tif_turno">
                        {{ item.tif_turno.bloque.nombre }}
                      </td>
                      <td v-else></td>
                      <td v-if="item.tif_turno">
                        {{ item.tif_turno.operacion.Noperacion }}
                      </td>
                      <td v-else></td>
                      <td v-if="item.tif_turno">
                        {{ item.tif_turno.ruta.nombre }}
                      </td>
                      <td v-else></td>
                      <td v-if="item.ultimo_registro">
                        {{ item.ultimo_registro.fecha_hora_ultimo_reporte }}
                      </td>
                      <td v-else></td>
                      <td v-if="item.ultimo_registro">
                        {{ item.ultimo_registro.gps.dblLatitud }}
                      </td>
                      <td v-else></td>
                      <td v-if="item.ultimo_registro">
                        {{ item.ultimo_registro.gps.dblLongitud }}
                      </td>
                      <td v-else></td>
                      <td v-if="item.ultimo_registro">
                        {{ item.ultimo_registro.gps.strDireccion }}
                      </td>
                      <td v-else></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="card-footer">
            <div class="float-left" v-if="$parent.vehiculosInAc.total">
              <p>
                Mostrando del
                <b>{{ $parent.vehiculosInAc.from }}</b> al
                <b>{{ $parent.vehiculosInAc.to }}</b> de un total:
                <b>{{ $parent.vehiculosInAc.total }}</b> Registros
              </p>
            </div>
            <div class="float-left" v-else>
              <p>
                <span class="badge badge-danger"
                  >No hay registros para mostrar</span
                >
              </p>
            </div>
            <pagination
              :data="$parent.vehiculosInAc"
              @pagination-change-page="$parent.getIndex"
              :limit="10"
              align="right"
            ></pagination>
          </div>
        </div>
        <div
          class="tab-pane fade"
          id="Activos"
          v-show="Object.keys($parent.vehiculosAct).length > 0"
        >
          <div class="card-body table-responsive">
            <div class="row">
              <div class="col-md-12">
                <table
                  id="cars"
                  class="
                    table table-bordered table-striped table-hover table-sm
                  "
                >
                  <thead>
                    <tr>
                      <th colspan="7"></th>
                      <th colspan="4" style="text-align: center">
                        Ultimo Registro
                      </th>
                    </tr>
                    <tr>
                      <th>
                        Turno
                      </th>
                      <th>
                        Vehículo
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.vehiculo_fil"
                          @keyup.enter="$parent.getIndex()"
                        />
                      </th>
                      <th>
                        Empresa
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.empresa_nombre"
                          @keyup.enter="$parent.getIndex()"
                        />
                      </th>
                      <th>
                        Conductor
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.conductor_nombre"
                          @keyup.enter="$parent.getIndex()"
                        />
                      </th>
                      <th>
                        Operación
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.operacion_ac"
                          @keyup.enter="$parent.getIndex()"
                        />
                      </th>
                      <th>
                        Ruta
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.ruta_ac"
                          @keyup.enter="$parent.getIndex()"
                        />
                      </th>
                      <th>
                        Bloque Postulación
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.bloque_ac"
                          @keyup.enter="$parent.getIndex()"
                        />
                      </th>
                      <th>Fecha última posición</th>
                      <th>Latitud</th>
                      <th>Longitud</th>
                      <th>Dirección</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="item in $parent.vehiculosAct.data"
                      :key="item.id"
                    >
                      <td>
                        {{ item.tif_turno.id }}
                      </td>
                      <td>
                        {{ item.placa }}
                      </td>
                      <td>
                        {{ item.empresa.razon_social }}
                      </td>
                      <td>
                        {{ item.tif_turno.conductor.apellidos }}
                        {{ item.tif_turno.conductor.nombres }}
                      </td>
                      <td>
                        {{ item.tif_turno.operacion.Noperacion }}
                      </td>
                      <td>
                        {{ item.tif_turno.ruta.nombre }}
                      </td>
                      <td>
                        {{ item.tif_turno.bloque.nombre }}
                      </td>
                      <td v-if="item.ultimo_registro">
                        {{ item.ultimo_registro.fecha_hora_ultimo_reporte }}
                      </td>
                      <td v-else></td>
                      <td v-if="item.ultimo_registro">
                        {{ item.ultimo_registro.gps.dblLatitud }}
                      </td>
                      <td v-else></td>
                      <td v-if="item.ultimo_registro">
                        {{ item.ultimo_registro.gps.dblLongitud }}
                      </td>
                      <td v-else></td>
                      <td v-if="item.ultimo_registro">
                        {{ item.ultimo_registro.gps.strDireccion }}
                      </td>
                      <td v-else></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="card-footer">
            <div class="float-left" v-if="$parent.vehiculosAct.total">
              <p>
                Mostrando del
                <b>{{ $parent.vehiculosAct.from }}</b> al
                <b>{{ $parent.vehiculosAct.to }}</b> de un total:
                <b>{{ $parent.vehiculosAct.total }}</b> Registros
              </p>
            </div>
            <div class="float-left" v-else>
              <p>
                <span class="badge badge-danger"
                  >No hay registros para mostrar</span
                >
              </p>
            </div>
            <pagination
              :data="$parent.vehiculosAct"
              @pagination-change-page="$parent.getIndex"
              :limit="10"
              align="right"
            ></pagination>
          </div>
        </div>
        <div
          class="tab-pane fade"
          id="Postulados"
          v-show="Object.keys($parent.vehiculosPostulados).length > 0"
        >
          <div class="card-body table-responsive">
            <div class="row">
              <div class="col-md-12">
                <table
                  id="cars"
                  class="
                    table table-bordered table-striped table-hover table-sm
                  "
                >
                  <thead>
                    <tr>
                      <th colspan="8"></th>
                      <th colspan="2" style="text-align: center">
                        Ultimo Registro
                      </th>
                    </tr>
                    <tr>
                      <th>
                        Vehiculo
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.vehiculo_filR"
                          @keyup.enter="$parent.getIndex()"
                        />
                      </th>
                      <th>
                        Empresa
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.empresa_nombreR"
                          @keyup.enter="$parent.getIndex()"
                        />
                      </th>
                      <th>
                        Conductor
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.conductor_nombreR"
                          @keyup.enter="$parent.getIndex()"
                        />
                      </th>
                      <th>
                        Bloque
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.bloque"
                          @keyup.enter="$parent.getIndex()"
                        />
                      </th>
                      <th>
                        Ruta
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.ruta"
                          @keyup.enter="$parent.getIndex()"
                        />
                      </th>
                      <th>
                        Operación
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.operacion"
                          @keyup.enter="$parent.getIndex()"
                        />
                      </th>
                      <th>
                        Tipo Vehículo
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.tipo_vehiculo"
                          @keyup.enter="$parent.getIndex()"
                        />
                      </th>
                      <th>Fecha de Solicitud</th>
                      <th>Latitud</th>
                      <th>Longitud</th>
                      <th>Dirección</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="item in $parent.vehiculosPostulados.data"
                      :key="item.id"
                    >
                      <td>
                        {{ item.placa }}
                      </td>
                      <td>
                        {{ item.empresa.razon_social }}
                      </td>
                      <td>
                        {{ item.tif_det_postulaciones[0].conductor.apellidos }}
                        {{ item.tif_det_postulaciones[0].conductor.nombres }}
                      </td>
                      <td>
                        {{
                          item.tif_det_postulaciones[0].solicitud.bloque.nombre
                        }}
                      </td>
                      <td>
                        {{
                          item.tif_det_postulaciones[0].det_solicitud.tif_ruta
                            .nombre
                        }}
                      </td>
                      <td>
                        {{
                          item.tif_det_postulaciones[0].det_solicitud
                            .tif_operacion.Noperacion
                        }}
                      </td>
                      <td>
                        {{
                          item.tif_det_postulaciones[0].det_solicitud
                            .tif_tipos_vehiculos.nombre
                        }}
                      </td>
                      <td>
                        {{ item.tif_det_postulaciones[0].fecha_ini }}
                      </td>
                      <td v-if="item.ultimo_registro">
                        {{ item.ultimo_registro.gps.dblLatitud }}
                      </td>
                      <td v-else></td>
                      <td v-if="item.ultimo_registro">
                        {{ item.ultimo_registro.gps.dblLongitud }}
                      </td>
                      <td v-else></td>
                      <td v-if="item.ultimo_registro">
                        {{ item.ultimo_registro.gps.strDireccion }}
                      </td>
                      <td v-else></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="card-footer">
            <div class="float-left" v-if="$parent.vehiculosPostulados.total">
              <p>
                Mostrando del
                <b>{{ $parent.vehiculosPostulados.from }}</b> al
                <b>{{ $parent.vehiculosPostulados.to }}</b> de un total:
                <b>{{ $parent.vehiculosPostulados.total }}</b> Registros
              </p>
            </div>
            <div class="float-left" v-else>
              <p>
                <span class="badge badge-danger"
                  >No hay registros para mostrar</span
                >
              </p>
            </div>
            <pagination
              :data="$parent.vehiculosPostulados"
              @pagination-change-page="$parent.getIndex"
              :limit="10"
              align="right"
            ></pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import pagination from "laravel-vue-pagination";

export default {
  name: "TepMapasListVehiculos",
  components: {
    pagination,
  },
  data() {
    return {
      filtros: {},
    };
  },

  methods: {},
};
</script>
